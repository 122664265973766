<template>
  <v-app id="app">
    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {},
  props: {},
  inject: {},
  data() {
    return {};
  },
  computed: {},

  mounted() {},
  beforeDestroy() {},
  beforeRouteUpdate(to, from, next) {},

  methods: {},
};
</script>

<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
    transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
  }
  to {
    opacity: 0;
    transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
